<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="apps_expire_alert_after" label="自动提醒" name="apps_expire_alert_after" help="品牌商功能模块到期提示时间(单位:天)">
          <a-input v-model:value="form.apps_expire_alert_after" v-inputFilter:int />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 2 }">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject } from 'vue';
import  {getSetting,setSetting} from '@/api/system'
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defForm = {
  apps_expire_alert_after: ''
};
export default {
  setup() {
    //pass
    const form = ref({ ...defForm });
    const state = reactive({
      labelCol: { span: 2 },
      wrapperCol: { span: 5},
      value:[],
      rules: {
        apps_expire_alert_after: [
          {
            required: true,
            message: '请输入时间',
          }
        ],
      },
    });
    const $message=inject('$message')
    const ruleForm = ref();
    // 获取基础设置
    const initData=async()=>{
        let result = await getSetting().then(res=>res.data).catch(error=>error)
        form.value=result.data
    }
    initData()
    // 提交
    const onSubmit = () => {
      ruleForm.value
        .validate()
        .then(async() => {
          let result = await setSetting({...form.value}).then(res=>res.data).catch(error=>error)
          $iscode(result,true)
        })
        .catch((error) => {
          $message.error('请完善表单')
        });
    };
    return {
      form,
      ...toRefs(state),
      ruleForm,
      onSubmit,
    };
  },
};
</script>
