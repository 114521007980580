import axios from '@/utils/request'
// 获取菜单列表
export const getMenu = ()=>{
 return axios.request({
    url: 'system/menu',
    method: 'get',
  })
};
// 添加菜单
export const createMenu = (data)=>{
  return axios.request({
     url: 'system/menu',
     method: 'post',
     data
   })
 };
// 修改菜单
export const editMenu = (id,data)=>{
  return axios.request({
     url: 'system/menu/'+id,
     method: 'put',
     data
   })
 };
// 批量删除菜单
export const delMenu = (ids)=>{
  return axios.request({
     url: 'system/menu/mutiDel',
     method: 'get',
     params:{ids}
   })
 };


// 用户列表

export const getAdmin = (params)=>{
  return axios.request({
     url: 'system/admin',
     method: 'get',
     params
   })
 };

// 用户详情
export const adminDetail = (id)=>{
  return axios.request({
     url: 'system/admin/'+id,
     method: 'get',
   })
 };
//  新增用户
export const createAdmin = (data)=>{
  return axios.request({
     url: 'system/admin',
     method: 'post',
     data
   })
 };
// 修改用户
export const editAdmin = (id,data)=>{
  return axios.request({
     url: 'system/admin/'+id,
     method: 'put',
     data
   })
 };
 // 删除用户
export const delAdmin = (id)=>{
  return axios.request({
     url: 'system/admin/'+id,
     method: 'DELETE'
   })
 };
 
 // 修改用户状态
export const statusAdmin = (status,id)=>{
  return axios.request({
     url: 'system/admin/'+id+'/'+status,
     method: 'put',
   })
 };
//  角色列表
export const getRole = (params)=>{
  return axios.request({
     url: 'system/role',
     method: 'get',
     params
   })
 };

//  新增角色
export const createRole = (data)=>{
  return axios.request({
     url: 'system/role',
     method: 'post',
     data
   })
 };
//  角色详情
export const roleDetail = (id,params)=>{
  return axios.request({
     url: 'system/role/'+id,
     method: 'get',
     params
   })
 };
// 删除角色
export const delRole = (id)=>{
  return axios.request({
     url: 'system/role/'+id,
     method: 'DELETE',
   })
 };
 // 修改角色
export const editRole = (id,data)=>{
  return axios.request({
     url: 'system/role/'+id,
     method: 'put',
     data
   })
 };

  
 // 修改用户状态
export const statusRole = (status,id)=>{
  return axios.request({
     url: 'system/role/'+id+'/'+status,
     method: 'put',
   })
 };

//  类目列表
export const getCate = (params)=>{
  return axios.request({
     url: 'system/cate',
     method: 'get',
     params
   })
 };

 //  类目新增
export const cretateCate = (data)=>{
  return axios.request({
     url: 'system/cate',
     method: 'post',
     data
   })
 };

 //  类目修改
export const editCate = (id,data)=>{
  return axios.request({
     url: 'system/cate/'+id,
     method: 'put',
     data
   })
 };
 //  类目删除
export const deleteCate = (id)=>{
  return axios.request({
     url: 'system/cate/'+id,
     method: 'DELETE',
   })
 };
 //  类目状态修改
export const statusCate = (id,status)=>{
  return axios.request({
     url: 'system/cate/'+id+'/'+status,
     method: 'put'
   })
 };



// 基础设置获取
export const getSetting = (params)=>{
  return axios.request({
     url: 'system/setting/info',
     method: 'get',
     params
   })
 };
 // 设置基础
export const setSetting = (data)=>{
  return axios.request({
     url: 'system/setting/save',
     method: 'post',
     data
   })
 };
 
 // 操作日志
 export const getLogs = (params)=>{
  return axios.request({
     url: 'system/logs',
     method: 'get',
     params 
   })
 };


 // 获取品牌商菜单列表
export const getbrandMenu = ()=>{
  return axios.request({
     url: 'brand/menu',
     method: 'get',
   })
 };
 // 添加品牌商菜单
 export const createbrandMenu = (data)=>{
   return axios.request({
      url: 'brand/menu',
      method: 'post',
      data
    })
  };
 // 修改品牌商菜单
 export const editbrandMenu = (id,data)=>{
   return axios.request({
      url: 'brand/menu/'+id,
      method: 'put',
      data
    })
  };
 // 批量删除品牌商菜单
 export const delbrandMenu = (ids)=>{
   return axios.request({
      url: 'brand/menu/mutiDel',
      method: 'get',
      params:{ids}
    })
  };
 // 分账账号
 export const getBlankConfig = (params)=>{
  return axios.request({
     url: 'system/adaBlankConfig',
     method: 'get',
     params
   })
 };

 // 代发详情
 export const paymentDetail = (params)=>{
  return axios.request({
     url: 'system/behalf_payment/detail',
     method: 'get',
     params
   })
 };
 
 // 代发设置修改
 export const paymentUpadte = (data)=>{
  return axios.request({
     url: 'system/behalf_payment/update',
     method: 'post',
     data
   })
 };

 // 菜单管理-获取应用
 export const getSystemApps = (params)=>{
  return axios.request({
     url: 'system/getApps',
     method: 'get',
     params
   })
 };
 /// 修改密码
 export const changePassword = (data)=>{
  return axios.request({
     url: 'admin/changePassword',
     method: 'put',
     data
   })
 };
 /// 用户管理 - 重置密码
 export const changeAdminPassword = (data)=>{
  return axios.request({
     url: 'system/reset/'+data.id,
     method: 'post',
     data
   })
 };

 //地区管理
 export const areaList = (params)=>{
  return axios.request({
     url: 'area/index',
     method: 'get',
     params
   })
 };

 //添加地区
 export const areaAdd = (data)=>{
  return axios.request({
     url: 'area/add',
     method: 'post',
     data
   })
 };

 //地区详情
 export const areaDetail = (params)=>{
  return axios.request({
     url: 'area/detail/'+params.id,
     method: 'get',
     params
   })
 };

  //更新地区
  export const areaUpdate = (data)=>{
    return axios.request({
       url: 'area/update/'+data.id,
       method: 'post',
       data
     })
   };

 
//  物流公司管理-列表
 export const expressList = (params)=>{
  return axios.request({
     url: 'express/index',
     method: 'get',
     params
   })
 };

 //  物流公司管理-详情
 export const expressDetail = (params)=>{
  return axios.request({
     url: 'express/detail/'+params.id,
     method: 'get',
     params
   })
 };

  //  物流公司管理-更新
  export const expressUpdate = (data)=>{
    return axios.request({
       url: 'express/update/'+data.id,
       method: 'post',
       data
     })
   };

   //  物流公司管理-添加
  export const expressAdd = (data)=>{
    return axios.request({
       url: 'express/add',
       method: 'post',
       data
     })
   };

      //  新物流列表
  export const expressLogistics = (params)=>{
    return axios.request({
       url: 'express/logistics',
       method: 'get',
       params
     })
   }
